import { FC, useEffect, useState } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import FavIcon from "../common/Package/Inititialize/favicon.svg";
import Copy from "../common/Package/Inititialize/Copy.svg";
import toast, { Toaster } from "react-hot-toast";
import RecognizeTeammates from "../common/Package/Inititialize/recognizeteammates-1.svg";
import RedemmVouchers from "../common/Package/Inititialize/redeemvouchers-1.svg";
import RewardTheirAchivements from "../common/Package/Inititialize/rewardtheirachivements-1.svg";

interface InitializeProps {}

const initializeDetails = [
  { title: "Recognize Teammates", image: RecognizeTeammates },
  { title: "Reward their achievements", image: RewardTheirAchivements },
  { title: "Redeem vouchers", image: RedemmVouchers },
];

const Initialize: FC<InitializeProps> = () => {
  const [appSpecificId, setAppSpecificId] = useState<string | undefined>();
  const app = microsoftTeams.app;

  useEffect(() => {
    const initializeApp = async () => {
      try {
        const context = await app.initialize().then(app.getContext);
        setAppSpecificId(context?.user?.tenant?.id || "");
      } catch (error) {
        console.error("Error initializing app:", error);
      }
    };

    initializeApp();
  }, [app]);

  const handleCopyClick = () => {
    if (appSpecificId) {
      navigator.clipboard.writeText(appSpecificId).then(
        () => {
          toast.success("Code copied to clipboard!");
          console.log("Code copied to clipboard!");
        },
        (err) => {
          console.log("Failed to copy code:", err);
        }
      );
    }
  };

  const maskAppSpecificId = (id: string | undefined) => {
    if (!id || id.length < 5) return id;
    const start = id.slice(0, 2);
    const middle = id.slice(2, -4);
    const end = id.slice(-4);
    return `${start}******${middle.slice(0, 3)}******${end}`;
  };

  return (
    <div className="min-h-screen">
      <div className="flex justify-center items-center h-full bg-white md:bg-[#eeeeee]">
        <div className="md:w-[70%] md:m-auto bg-[#FCF9FF] p-6">
          <div>
            <img className="mx-auto my-5" src={FavIcon} alt="Inititialize" />
            <div className="md:text-4xl text-xl font-bold text-center">
              🎉 Welcome to Reward Rally! 🎉
            </div>
            <div className="my-2 text-center font-semibold">
              Boost employee engagement right in Microsoft Teams
            </div>
            <div className="flex flex-row md:gap-24 gap-4 justify-center items-center mt-8">
              {initializeDetails.map((detail) => (
                <div className="flex flex-col text-center">
                  <div className="flex justify-center items-center">
                    <img
                      src={detail.image}
                      alt=""
                      className="object-cover md:w-[7.5rem] w-[5rem] md:h-[7.5rem] h-[5rem]"
                    />
                  </div>
                  <div>
                    <p className="font-semibold text-[0.75rem] mt-1 text-[#9E9E9E]">
                      {detail.title}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex flex-col items-center justify-center mt-8 mb-5">
              <div className="bg-[#FFF8EC] rounded-md p-2 flex flex-col items-center justify-center md:w-[70%]">
                <p className="text-center">
                  Please share this below ID with your IT admin to configure
                  Reward Rally
                </p>
                <div className="flex gap-4 items-center justify-center mt-6 mb-3">
                  <div className="border font-bold text-[#639] border-[#639] rounded-sm p-1">
                    {maskAppSpecificId(appSpecificId)}
                  </div>
                  <button
                    className="border border-[#639] rounded-md p-1"
                    onClick={handleCopyClick}
                  >
                    <img src={Copy} alt="Copy code" />
                  </button>
                </div>
              </div>
            </div>
            <div className="font-bold text-center mt-8">
              Know more about Reward Rally
            </div>
            <div className="flex justify-center my-3">
              <div className=""></div>
              <a
                href="https://rewardrally.in/#contact"
                target="_blank"
                className="font-semibold flex h-10 bg-primary px-3 py-2 rounded text-white justify-center"
              >
                Connect with us
              </a>
            </div>
          </div>
        </div>
      </div>
      <Toaster position="bottom-center" reverseOrder={true} />
    </div>
  );
};

export default Initialize;
