import { User, UserInput } from "../models/UserDetails";

import AxiosServices from "./AxiosServices";
import { URL } from "../config/URL.Config";
import { getUserInfos } from "./LeaderBoardService";

const instance = new AxiosServices().getServiceInstance();
export const updateUser = async (user: User, userInput: UserInput) => {
  const res = await instance.put(
    URL.users.update(userInput.userId, userInput.appId),
    user
  );
  return res.data.data;
};

export const getAvatarImages = async () => {
  const avatarImages = await instance.get(URL.avatar.getavatar());
  return avatarImages.data.data;
};

export const getUserExistence = async (userName: string, userId: string) => {
  const avatarImages = await instance.get(
    URL.avatar.getUserExistence(userName, userId)
  );
  return avatarImages.data.data;
};

export const createNewUser = (
  userId: string,
  userName: string,
  applicationId: string
): any => {
  return {
    userId: userId,
    userName: userName,
    customAttributes: {
      Role: ["Employee"],
    },
    application: [applicationId],
  };
};
export const addUser = async (user: any) => {
  const res = await instance.post(URL.users.add(), user);
  return res?.data?.data;
};

export const executeUserFlow = async (
  userId: string,
  userName: string,
  applicationId: string
): Promise<boolean> => {
  try {
    const userInfoResponse = await getUserInfos(userId, applicationId);
    if (userInfoResponse?.data?.data === null) {
      const newUser = createNewUser(userId, userName, applicationId);
      try {
        await addUser(newUser);
      } catch (error) {
        console.error(error);
        return false;
      }
    }
    return true;
  } catch (error) {
    return false;
  }
};
