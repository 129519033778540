import { FC, useContext, useEffect, useState, useRef } from "react";
import Coins from "../../../common/Package/WalletHistory/coins.svg";
import Purse from "../../../common/Package/WalletHistory/purse.svg";
import PreviousIcon from "../../../common/Package/WalletHistory/previous.svg";
import NextIcon from "../../../common/Package/WalletHistory/next.svg";
import Filter from "../../../common/Package/WalletHistory/filter.svg";
import { getUserTransaction } from "../../../api/recognition.service";
import ReactPaginate from "react-paginate";
import { useWallet } from "../Context/Wallet";
import { TeamsFxContext } from "../../Context";
import { useData } from "@microsoft/teamsfx-react";
import Loader from "../Core/Loader";
import { navigateBetweenTabs } from "../../../api/NavigationService";
import vector from "../../../common/Package/Reward/vector.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../context/store";
import NoDataFound from "../../../common/Package/Recognition/no-data-found.svg";
import { CoinFilterType } from "../../../enums/CoinFilterType.enum";
import dayjs from "dayjs";
interface WalletHistoryProps {
  fromDate: Date;
  toDate: Date;
}

const WalletHistory: FC<WalletHistoryProps> = ({ fromDate, toDate }) => {
  const applicationId = useSelector(
    (state: RootState) => state.application.applicationId
  );
  const [walletHistory, setWalletHistory] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [filterDropdownVisible, setFilterDropdownVisible] = useState(false);
  const [itemsPerPage] = useState<number>(7);
  const [totalCount, setTotalCount] = useState<number>(0);
  const { wallet } = useWallet();
  const [loader, setLoader] = useState(false);
  const { teamsUserCredential } = useContext(TeamsFxContext);
  const { loading, data, error } = useData(async () => {
    if (teamsUserCredential) {
      const userInfo = await teamsUserCredential.getUserInfo();
      return userInfo;
    }
  });
  const userEmail = loading || error ? "" : data!.preferredUserName;
  const [coinFilterType, setCoinFilterType] = useState<number>(null);
  const dropDown = useRef(null);

  const filterOptions = [
    { label: "Show All", value: CoinFilterType.SHOW_ALL },
    { label: "Coins", value: CoinFilterType.COINS },
    { label: "Recognition", value: CoinFilterType.RECOGNITION },
    { label: "Milestone reached", value: CoinFilterType.MILESTONE_REACHED },
  ];

  useEffect(() => {
    if (!loading && applicationId && userEmail) {
      setLoader(true);
      const user = {
        userId: userEmail,
        applicationId: applicationId,
        fromDate: fromDate,
        toDate: toDate,
        pageSize: itemsPerPage,
        pageNumber: currentPage + 1,
        CoinConversionType: coinFilterType,
      };
      try {
        getUserTransaction(user).then((response) => {
          if (response) {
            setWalletHistory(response?.data?.auditLogs);
            setTotalCount(response?.data?.totalCount);
          }
        });
      } catch (error) {
        console.error("Failed to fetch wallet history:", error);
        setWalletHistory([]);
        setTotalCount(0);
      } finally {
        setLoader(false);
      }
    }
  }, [currentPage, userEmail, applicationId, fromDate, toDate, coinFilterType]);

  const handlePageClick = (event: any) => {
    setCurrentPage(event.selected);
  };

  const handleClickOutside = (event) => {
    if (dropDown.current && !dropDown.current.contains(event.target)) {
      setFilterDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const pageCount = Math.ceil(totalCount / itemsPerPage);

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return dayjs(date).format("DD MMM, YYYY");
  };

  const formatTime = (dateString: string): string => {
    const time = new Date(dateString);
    return dayjs(time).format("hh:mm a");
  };

  const toggleFilterDropdown = () => {
    setFilterDropdownVisible(!filterDropdownVisible);
  };

  const applyCoinFilter = (filterType: number) => {
    setCoinFilterType(filterType);
  };

  return (
    <div className="bg-[#eeeeee] md:h-[80vh]" data-testid="WalletHistory">
      <div className="bg-white">
        <div className="flex justify-between py-2 px-5 items-center rounded-md bg-[#FCF9FF] relative header">
          <div className="flex flex-col md:gap-2 gap-1 text-black">
            <div className="md:text-[2em] text-[1.5em] font-semibold mb-3 mt-[1rem]">
              Congratulations!
            </div>
            <div className="md:text-[2em] text-[1.2em] md:w-[70%] w-[90%] md:leading-10 leading-6">
              You have{" "}
              <span className="text-primary font-bold">{wallet?.balance}</span>{" "}
              <span className="text-primary">coins</span> to redeem your
              vouchers{" "}
              <img
                src={Coins}
                alt="home-banner"
                className="md:hidden max-w-[8%] ml-2 inline-flex"
              />
            </div>
            <div className="flex gap-32 items-center">
              <a href="#/redeem" className="block md:hidden">
                <button className="border my-3 border-primary text-primary rounded md:p-2 p-1 font-[700]">
                  Redeem your coins
                </button>
              </a>
              <button
                className="hidden md:block border my-2 border-primary text-primary rounded md:p-2 p-1 font-[700]"
                onClick={navigateBetweenTabs("redeem")}
              >
                Redeem your coins
              </button>
              <img
                src={Coins}
                alt="home-banner"
                className="hidden md:block w-[10%]"
              />
            </div>
          </div>
          <div className="relative">
            <img
              src={Purse}
              alt="home-banner"
              className="absolute md:max-w-[80%] w-[100%]"
            />
            <img
              src={vector}
              alt="home-banner"
              className="mt-10 md:max-w-[80%] w-[100%]"
            />
          </div>
        </div>
        <div className="bg-white py-4 px-4 md:px-0 h-[45vh]">
          <div className="flex justify-between items-center">
            <h2 className="text-[1.5em] font-bold md:pl-12">All Coins</h2>
            <div className="relative md:pr-12" ref={dropDown}>
              <button onClick={toggleFilterDropdown}>
                <img src={Filter} alt="filter" />
              </button>
              {filterDropdownVisible && (
                <div className="absolute bg-white border shadow-lg right-0 z-10 mt-2 w-[10rem]">
                  <div className="flex flex-col items-start">
                    {filterOptions.map((option) => (
                      <button
                        key={option.value}
                        className="py-2 px-4 hover:bg-gray-100 w-[100%] text-start"
                        onClick={() => applyCoinFilter(option.value)}
                      >
                        {option.label}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="wallet-history md:h-[35vh] h-[40vh] my-3 overflow-auto bg-white">
            {walletHistory?.length ? (
              <table className="w-full border-collapse">
                <tbody>
                  {walletHistory.map((wallet) => {
                    const formattedDate = formatDate(wallet.createdOn);
                    const formattedTime = formatTime(wallet.createdOn);
                    return (
                      <tr key={wallet.id} className="border-b py-2">
                        <td className="md:py-5 py-3 md:w-[25%] w-[38%] text-sm md:pl-12">
                          {wallet.eventType === 1
                            ? "Recognition Coins"
                            : "Milestone Crossed Coins"}
                        </td>
                        <td className="md:py-5 py-3 md:w-[25%] w-[35%] text-sm text-center">
                          {formattedDate}
                        </td>
                        <td className="md:py-5 py-3 md:w-[25%] w-[35%] text-sm text-center">
                          {formattedTime}
                        </td>
                        <td className="md:py-5 py-3 text-[#199644] font-bold flex justify-end md:pr-12">
                          +{wallet.virtualMoneyCount}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className="flex justify-center items-center h-[100%]">
                <img
                  src={NoDataFound}
                  alt="No data found"
                  className="md:w-[20%] w-[50%]"
                />
              </div>
            )}
          </div>
        </div>
        {pageCount > 1 && (
          <div className="flex md:justify-end justify-center mt-7 md:mt-0">
            <ReactPaginate
              previousLabel={
                <img className="-mt-5 mx-2" src={PreviousIcon} alt="Previous" />
              }
              nextLabel={
                <img className="-mt-5 mx-2" src={NextIcon} alt="Next" />
              }
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={4}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
              nextClassName={"next"}
              previousClassName={"previous"}
            />
          </div>
        )}
      </div>
      {loader && <Loader />}
    </div>
  );
};

export default WalletHistory;
