import { FC, useEffect, useState } from "react";
import { getAllRedeemRules } from "../../../api/RedeemService";
import { getSasToken } from "../../../api/LeaderBoardService";
import { Link } from "react-router-dom";
import { useProduct } from "../Context/Product";
import "./Redeem.css";
import Loader from "../Core/Loader";
import { useSelector } from "react-redux";
import { RootState } from "../../../context/store";
import NoDataFound from "../../../common/Package/Recognition/no-data-found.svg";
const RedeemableProductList: FC = () => {
  const applicationId = useSelector(
    (state: RootState) => state.application.applicationId
  );
  const [redeemData, setData] = useState<any>([]);
  const [sasToken, setSasToken] = useState<string>("");
  const { setProduct } = useProduct();
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setLoader(true);
    getAllRedeemRules(applicationId)
      .then((responseData: any) => {
        setData(responseData);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.error("Error fetching data:", error);
      });

    getSasToken()
      .then((sasToken: string) => {
        setSasToken(sasToken);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleSetProduct = (product: any) => {
    setProduct(product);
  };

  return (
    <div>
      <div className="bg-white overflow-auto h-[80vh] coupon-list">
        <div className="list-product modal-content">
          <div className="products p-5">
            {redeemData?.length > 0 ? (
              <div className="list-products grid md:grid-cols-3 gap-5">
                {redeemData.map((product: any) => (
                  <Link to={`/detail-product`}>
                    <div
                      onClick={() => handleSetProduct(product)}
                      className="flex cursor-pointer flex-col border rounded-md shadow-lg h-auto w-[90%] m-auto"
                      key={product._id}
                    >
                      <img
                        className="h-[10em] rounded-sm"
                        src={product?.productImageUrl}
                        alt="product-image"
                      />
                      <div className="product-detail px-5 py-2">
                        <div className="ellipsis font-bold">
                          {product?.productName}
                        </div>
                        <div className="coin flex items-center gap-2 my-2 ">
                          <img
                            alt="virtual-money-image"
                            className="w-[1.5em] h-[1.5em] rounded-full"
                            src={product?.virtualMoneyImageUrl + sasToken}
                          />
                          <span>{product?.eligibleMoney + " coins"}</span>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            ) : (
              <div className="flex justify-center items-center h-[90%]">
                <img
                  src={NoDataFound}
                  alt="No data found"
                  className="md:w-[20%] w-[50%]"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {loader && <Loader />}
    </div>
  );
};

export default RedeemableProductList;
