import { createRoot } from "react-dom/client";
import App from "./components/App";
import "./index.css";
import { ProductProvider } from "./components/Package/Context/Product";
import { WalletProvider } from "./components/Package/Context/Wallet";
import { PopupService } from "./api/PopupService";
import { Provider } from "react-redux";
import store from "./context/store";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <ProductProvider>
      <WalletProvider>
        <PopupService>
          <App />
        </PopupService>
      </WalletProvider>
    </ProductProvider>
  </Provider>
);
