import { RefreshEvents } from "../enums/RefreshEvents";
import { createSignal } from "@react-rxjs/utils";
import { merge } from "rxjs";
import { v4 as uuid } from "uuid";

export type RefreshEvent = {
	id?: string;
	name: RefreshEvents;
	data: {
		[key: string]: unknown;
	};
};

const [event$, setEvent] = createSignal<RefreshEvent | undefined>();

export const eventMap$ = merge(event$);

const dispatch = (event: RefreshEvent): void => {
	if (!event.id) {
		event.id = uuid();
	}

	setEvent(event);
};

export default Object.freeze({
	dispatch,
});
