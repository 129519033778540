import { FC, useContext, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import LeftBaloon from "../../../common/Package/Recognition/Mask-group-left.svg";
import RightBaloon from "../../../common/Package/Recognition/Mask-group-right.svg";
import RecognitionImage from "../../../common/Package/Recognition/rr.svg";
import { useWallet } from "../Context/Wallet";
import { getSasToken } from "../../../api/LeaderBoardService";
import { getRecognitionHistory } from "../../../api/recognition.service";
import PreviousIcon from "../../../common/Package/WalletHistory/previous.svg";
import NextIcon from "../../../common/Package/WalletHistory/next.svg";
import { env } from "../../../env/env";
import { TeamsFxContext } from "../../Context";
import { useData } from "@microsoft/teamsfx-react";
import Loader from "../Core/Loader";
import { useSelector } from "react-redux";
import { RootState } from "../../../context/store";
import Balloon from "../../../common/Package/Recognition/balloon.svg";
import NoDataFound from "../../../common/Package/Recognition/no-data-found.svg";
import dayjs from "dayjs";
import "./Recognition.css";

interface RecognitionReceivedProps {
  fromDate: Date;
  toDate: Date;
}

const RecognitionReceived: FC<RecognitionReceivedProps> = ({
  fromDate,
  toDate,
}) => {
  const applicationId = useSelector(
    (state: RootState) => state.application.applicationId
  );
  const { wallet } = useWallet();
  const [userRecognitionsHistory, setUserRecognitionsHistory] = useState<any[]>(
    []
  );
  const [loader, setLoader] = useState<boolean>(false);
  const [sasToken, setSasToken] = useState<string>("");
  const [pageCount, setPageCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const { teamsUserCredential } = useContext(TeamsFxContext);
  const { loading, data, error } = useData(async () => {
    if (teamsUserCredential) {
      const userInfo = await teamsUserCredential.getUserInfo();
      return userInfo;
    }
  });

  const userEmail = loading || error ? "" : data!.preferredUserName;

  useEffect(() => {
    if (!loading && userEmail) {
      const fetchData = async () => {
        try {
          const token = await getSasToken();
          setSasToken(token);

          await getUserRecognitionDetails(pageNumber);
        } catch (err) {
          console.error("Error fetching data:", err);
        }
      };

      fetchData();
    }
  }, [pageNumber, fromDate, toDate, userEmail]);

  const getUserRecognitionDetails = async (page: number) => {
    setLoader(true);
    const filter = {
      recognitionType: "received",
      userId: userEmail,
      application: applicationId,
      fromDate: dayjs(fromDate).format("YYYY-MM-DD:00:00Z"),
      toDate: dayjs(toDate).format("YYYY-MM-DD:23:59Z"),
      pageSize: 3,
      pageNumber: page,
    };

    try {
      const response: any = await getRecognitionHistory(filter);
      if (response) {
        setUserRecognitionsHistory(response?.recognitions || []);
        setPageCount(Math.ceil((response?.totalCount || 0) / filter.pageSize));
      }
    } catch (error) {
      console.error("Error fetching recognition details:", error);
    } finally {
      setLoader(false);
    }
  };

  const handlePageClick = (data: any) => {
    setPageNumber(data.selected + 1);
  };

  const coinErrorHandler = (
    e: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    e.currentTarget.src = `${env.BLOB_URL}/${env.CONTAINER_PATH}/coins.png`;
  };

  const formatDateTime = (dateString: string): string => {
    const date = new Date(dateString);
    return dayjs(date).format("DD MMM, hh:mm a");
  };

  return (
    <div className="bg-white md:h-[80vh]">
      <div>
        <div className="header md:flex hidden justify-between items-center pt-2 pl-3 pr-3 bg-[#faf5ff]">
          <img alt="right-baloon" src={LeftBaloon} className="w-[25%]" />
          <div className="flex-1 text-center">
            <div className="text-2xl font-[700]">Congratulations!</div>
            <div className="text-3xl flex items-center justify-center my-4 font-semibold">
              You have
              <img
                className="w-[1em] h-[1em] rounded-full coins mx-2"
                src={`${wallet?.imageUrl}${sasToken}`}
                onError={coinErrorHandler}
                alt="Coins"
              />
              {wallet?.balance || 0} Received Coins
            </div>
          </div>
          <img alt="right-baloon" src={RightBaloon} className="w-[25%]" />
        </div>
        <div className="max-w-[100%] flex md:hidden justify-center items-center relative">
          <img
            src={Balloon}
            alt=""
            className="w-[100%] h-[20vh] object-cover rounded-md block"
          />
          <div className="absolute text-center text-black">
            <h2 className="text-2xl font-[700]">Congratulations!</h2>
            <p className="text-xl flex items-center justify-center my-4 font-semibold">
              You have Received{" "}
              <span className="mx-2 font-bold">{wallet?.balance || 0}</span>{" "}
              Coins
            </p>
          </div>
        </div>
        <div className="recognition-history md:px-6 px-2 pr-4 md:pr-4 md:pt-5 pb-3 bg-white my-3 h-[53vh] overflow-auto">
          {userRecognitionsHistory.length > 0 ? (
            userRecognitionsHistory.map((recognition) => (
              <div key={recognition.id} className="">
                <div className="flex justify-start my-1 gap-2">
                  <div className="flex flex-col items-center">
                    <img
                      alt=""
                      src={RecognitionImage}
                      className="-mt-[0.5rem] w-[90%]"
                    />
                    <div className="border-l-2 border-primary border-dashed h-full"></div>
                  </div>
                  <div className="w-full pb-3">
                    <div className="flex items-center justify-between mb-3 gap-3">
                      <div>
                        <span className="coin rounded-full py-2 px-3 text-[#199644] bg-[#E9FFF1] font-[700]">
                          + {recognition.count}
                        </span>
                        <span className="px-2 text-base font-[500]">
                          {recognition.receiverUserName}
                        </span>
                      </div>
                      <div className="text-[0.75rem] font-[500]">
                        {formatDateTime(recognition.createdAt)}
                      </div>
                    </div>
                    <div className="bg-[#E8EBFA] rounded-md md:p-2 p-1 pl-4">
                      <div className="md:my-2 my-1 text-[#663399] font-[600] text-[1rem]">
                        From{" "}
                        <span className="text-black font-[600]">
                          {recognition.senderUserName}:{" "}
                        </span>
                      </div>
                      <div className="text-[#5B5FC6] md:my-2 my-1 text-[0.85rem] leading-[1.25rem] font-[500]">
                        {recognition.message}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="flex justify-center items-center h-[100%]">
              <img
                src={NoDataFound}
                alt="No data found"
                className="md:w-[20%] w-[50%]"
              />
            </div>
          )}
        </div>
        {pageCount > 1 && (
          <div className="flex md:justify-end justify-center mt-2">
            <ReactPaginate
              previousLabel={
                <img className="-mt-5 mx-2" src={PreviousIcon} alt="Previous" />
              }
              nextLabel={
                <img className="-mt-5 mx-2" src={NextIcon} alt="Next" />
              }
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={4}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
              previousClassName={"previous"}
              nextClassName={"next"}
            />
          </div>
        )}
      </div>
      {loader && <Loader />}
    </div>
  );
};

export default RecognitionReceived;
