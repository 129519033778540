import { FC } from "react";
import { SyncLoader } from "react-spinners";

const Loader: FC = () => {
  return (
    <div className="absolute inset-0 bg-[#FCF9FF] bg-opacity-80 z-10">
      <div
        role="status"
        className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
      >
        <SyncLoader color={"#663399"} loading={true} size={13} />
      </div>
    </div>
  );
};

export default Loader;
