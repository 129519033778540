import { FC, useContext, useEffect, useState } from "react";
import { getTransactionHistory } from "../../../api/RedeemService";
import { getSasToken } from "../../../api/LeaderBoardService";
import { parseISO, format } from "date-fns";
import { TeamsFxContext } from "../../Context";
import { useData } from "@microsoft/teamsfx-react";
import Loader from "../Core/Loader";
import { useSelector } from "react-redux";
import { RootState } from "../../../context/store";
import NoDataFound from "../../../common/Package/Recognition/no-data-found.svg";
interface CouponRedeemedProps {}

const CouponRedeemed: FC<CouponRedeemedProps> = () => {
  const applicationId = useSelector(
    (state: RootState) => state.application.applicationId
  );
  const [redeemData, setData] = useState<any>([]);
  const [sasToken, setSasToken] = useState<string>("");
  const [loader, setLoader] = useState(false);
  const { teamsUserCredential } = useContext(TeamsFxContext);
  const { loading, data, error } = useData(async () => {
    if (teamsUserCredential) {
      const userInfo = await teamsUserCredential.getUserInfo();
      return userInfo;
    }
  });
  const userEmail = loading || error ? "" : data!.preferredUserName;

  useEffect(() => {
    if (!loading && userEmail) {
      setLoader(true);
      getTransactionHistory(userEmail, applicationId)
        .then((responseData: any) => {
          setData(responseData);
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          console.error("Error fetching data:", error);
        });

      getSasToken()
        .then((sasToken: string) => {
          setSasToken(sasToken);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [userEmail]);

  const formatDateTime = (dateString: string): string => {
    const date = parseISO(dateString);
    return format(date, "dd-MMM-yy hh:mm a");
  };

  return (
    <div>
      <div className="bg-white overflow-auto h-[80vh] coupon-list">
        <div className="list-product modal-content ">
          <div className="products p-5">
            {redeemData?.length > 0 ? (
              <div className="list-products grid md:grid-cols-3 md:gap-10 gap-5">
                {redeemData.map((product: any) => (
                  <div
                    className="flex flex-col border rounded-md shadow-lg h-auto m-auto w-[90%]"
                    key={product._id}
                  >
                    <img
                      className="h-[10em] rounded-sm"
                      src={product?.productImageUrl}
                      alt="product-image"
                    />
                    <div className="product-detail px-5 py-2">
                      <div className="ellipsis font-bold">
                        {product?.productName}
                      </div>
                      <div className="coin flex items-center gap-2 my-2 ">
                        <img
                          alt="virtual-money-image"
                          className="w-[1.5em] h-[1.5em] rounded-full"
                          src={product?.virtualMoneyImageUrl + sasToken}
                        />
                        <span>{product?.virtualMoneyCount + " coins"}</span>
                      </div>
                      <div>
                        Order Placed on:
                        {" " + formatDateTime(product?.createdOn)}
                      </div>
                      <div className="my-1">
                        Status :{" "}
                        <span
                          className={`${
                            product.orderStatus === "COMPLETE"
                              ? "text-green-600"
                              : "text-red-600"
                          }`}
                        >
                          {product?.orderStatus === "COMPLETE"
                            ? "COMPLETED"
                            : product?.orderStatus}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex justify-center items-center h-[90%]">
                <img
                  src={NoDataFound}
                  alt="No data found"
                  className="md:w-[20%] w-[50%]"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {loader && <Loader />}
    </div>
  );
};

export default CouponRedeemed;
