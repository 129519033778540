import { FC, useContext, useEffect, useState } from "react";
import { getSasToken } from "../../../api/LeaderBoardService";
import { useForm } from "react-hook-form";
import { redeemProduct } from "../../../api/RedeemService";
import { useNavigate, useParams } from "react-router-dom";
import { env } from "../../../env/env";
import BillingImage from "../../../common/Package/Redeem/billing-image.svg";
import { TeamsFxContext } from "../../Context";
import { useData } from "@microsoft/teamsfx-react";
import { usePopup } from "../../../api/PopupService";
import PopUpStyles from "../../../common/styles/PopUp.styles";
import { useProduct } from "../Context/Product";
import { useWallet } from "../Context/Wallet";
import RedeemConfirmation from "../RedeemProduct/RedeemConfirmation/RedeemConfirmation";
import RedeemFailure from "../RedeemProduct/RedeemFailure/RedeemFailure";
import Loader from "../Core/Loader";
import { navigateBetweenTabs } from "../../../api/NavigationService";
import { useSelector } from "react-redux";
import { RootState } from "../../../context/store";
import { Link, useLocation } from "react-router-dom";

const BillingForm: FC = () => {
  const { openPopup } = usePopup();
  const [sasToken, setSasToken] = useState<string>("");
  const { product } = useProduct();
  const { wallet } = useWallet();
  const applicationId = useSelector(
    (state: RootState) => state.application.applicationId
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [redeemLoader, setRedeemLoader] = useState(false);
  const navigate = useNavigate();
  const { teamsUserCredential } = useContext(TeamsFxContext);
  const { loading, data, error } = useData(async () => {
    if (teamsUserCredential) {
      const userInfo = await teamsUserCredential.getUserInfo();
      return userInfo;
    }
  });
  const userEmail = loading || error ? "" : data!.preferredUserName;
  const location = useLocation();

  useEffect(() => {
    getSasToken()
      .then((sasToken: string) => {
        setSasToken(sasToken);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleGoToWallet = () => {
    navigateBetweenTabs("rewards");
  };
  const handleGoToProducts = () => {
    navigateBetweenTabs("redeem");
  };

  const onSubmit = async (userDetails: any) => {
    setRedeemLoader(true);
    let redeemDetails = {
      email: userDetails.email,
      firstName: userDetails.firstName + " " + userDetails.lastName,
      telephone: userDetails.countryCode + userDetails.telephone,
      redeemRuleId: product?.id,
      gamificationUserId: userEmail,
      gamificationApplicationId: applicationId,
    };

    try {
      const response: any = await redeemProduct(redeemDetails);
      if (!response.data.message) {
        openPopup(
          <PopUpStyles.Container>
            <RedeemConfirmation
              product={product}
              userId={userEmail}
              applicationId={applicationId}
              walletData={wallet}
              navigationToRewards={handleGoToWallet}
              navigationToProducts={handleGoToProducts}
            />
          </PopUpStyles.Container>
        );
        reset();
        setRedeemLoader(false);
      } else {
        openPopup(
          <PopUpStyles.Container>
            <RedeemFailure
              product={product}
              userId={userEmail}
              applicationId={applicationId}
              walletData={wallet}
              failureMsg={response.data.message}
              navigationToRewards={handleGoToWallet}
            />
          </PopUpStyles.Container>
        );
        setRedeemLoader(false);
      }
    } catch (error) {
      openPopup(
        <PopUpStyles.Container>
          <RedeemFailure
            product={product}
            userId={userEmail}
            applicationId={applicationId}
            walletData={wallet}
            failureMsg={error.message}
            navigationToRewards={handleGoToWallet}
          />
        </PopUpStyles.Container>
      );
      setRedeemLoader(false);
    }
  };
  const coinErrorHandler = (e) => {
    e.target.src = `${env.BLOB_URL}/${env.CONTAINER_PATH}/coins.png`;
  };

  const errorMessage = (message) => {
    return <span className="text-red-500 text-sm">{message}</span>;
  };

  const getBreadcrumb = () => {
    switch (location.pathname) {
      case "/billing-form/":
        return (
          <>
            <Link to="/redeem">List of Coupons</Link> &gt;{" "}
            <Link to="/detail-product">Redeem now</Link> &gt;{" "}
            <span className="text-[#663399]"> Billing Address</span>
          </>
        );
      default:
        return "List of Coupons";
    }
  };

  return (
    <div>
      <div className="min-h-screen bg-white md:bg-[#eeeeee]">
        <div className="p-2 md:w-[98%] w-[100%] m-auto bg-white font-bold md:text-lg text-sm flex justify-between px-6">
          <div className="border-r-2 pr-3 font-[400]">{getBreadcrumb()}</div>
          <div className="flex gap-3 items-center">
            <img
              className="w-[1.5em] h-[1.5em]"
              src={wallet?.imageUrl + sasToken}
              alt=""
              onError={(e) => coinErrorHandler(e)}
            />
            <div>{wallet?.balance || 0 + " coins"}</div>
          </div>
        </div>
        <div className="flex items-center justify-center md:h-[94%] h-[90%]">
          <div className="bg-white md:w-[70%] w-[100%] md:my-6 m-auto h-auto">
            <div className="flex justify-center">
              <img
                src={BillingImage}
                alt="post-image"
                className="w-[50%] md:w-[30%] m-auto"
              />
            </div>
            <form className="w-[80%] m-auto" onSubmit={handleSubmit(onSubmit)}>
              <div className="flex justify-center md:flex-row flex-col items-center md:gap-20 gap-2">
                <div className="flex flex-col md:w-1/2 w-[100%]">
                  <label
                    className="text-[#686868] md:text-xs text-sm  md:my-2"
                    htmlFor="Name"
                  >
                    First Name<span className="text-red-500 pl-1">*</span>
                  </label>
                  <input
                    type="text"
                    className="outline-none focus:outline-none border-b-2 border-b-[#5B5FC7] mb-2 pt-2 pb-2 rounded-none"
                    placeholder="Enter First Name"
                    {...register("firstName", {
                      required: true,
                    })}
                  />
                  {errors.firstName?.type === "required" &&
                    errorMessage("First Name is required")}
                </div>
                <div className="flex flex-col md:w-1/2 w-[100%]">
                  <label
                    className="text-[#686868] md:text-xs text-sm my-2"
                    htmlFor="Name"
                  >
                    Last Name<span className="text-red-500 pl-1">*</span>
                  </label>
                  <input
                    type="text"
                    className="outline-none focus:outline-none border-b-2 border-b-[#5B5FC7] mb-2 pt-2 pb-2 rounded-none"
                    placeholder="Enter Last Name"
                    {...register("lastName", {
                      required: true,
                    })}
                  />
                  {errors.lastName?.type === "required" &&
                    errorMessage("Last Name is required")}
                </div>
              </div>
              <div className="flex justify-center items-center md:gap-20 gap-2 md:mt-8 mt-2 md:flex-row flex-col">
                <div className="flex flex-col md:w-1/2 w-[100%]">
                  <label
                    className="text-[#686868] md:text-xs text-sm my-2"
                    htmlFor="contact"
                  >
                    Contact Number<span className="text-red-500 pl-1">*</span>
                  </label>
                  <div className="flex">
                    <select
                      className="outline-none focus:outline-none border-b-2 rounded-none border-b-[#5B5FC7] mb-2 pt-2 pb-2 bg-[#fff]"
                      {...register("countryCode")}
                    >
                      <option value="+91">+91</option>
                      <option value="+1">+1</option>
                    </select>
                    <input
                      className="outline-none focus:outline-none border-b-2 border-b-[#5B5FC7] mb-2 w-full pt-2 pb-2 rounded-none"
                      placeholder="Enter Your Contact Number"
                      {...register("telephone", {
                        required: true,
                        pattern: {
                          value: /^\d{10}$/,
                          message: "Please enter a valid 10-digit phone number",
                        },
                      })}
                    />
                  </div>
                  {errors.telephone?.type == "required" &&
                    errorMessage("Contact Number is required")}
                  {errors.telephone?.type == "pattern" &&
                    errorMessage(errors.telephone.message as string)}
                </div>
                <div className="flex flex-col md:w-1/2 w-[100%]">
                  <label
                    className="text-[#686868] md:text-xs text-sm my-2"
                    htmlFor="Email"
                  >
                    Email<span className="text-red-500 pl-1">*</span>
                  </label>
                  <input
                    type="text"
                    className="outline-none focus:outline-none border-b-2 border-b-[#5B5FC7] mb-2 pt-2 pb-2 rounded-none"
                    placeholder="Enter Your Email"
                    {...register("email", {
                      required: true,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Invalid email address",
                      },
                    })}
                  />
                  {errors.email && errorMessage(errors.email.message as string)}
                  {errors.email?.type == "required" &&
                    errorMessage("Email is required")}
                </div>
              </div>
              <div className="pb-14">
                <button
                  type="submit"
                  className="bg-primary rounded-md text-white p-2 mt-5 "
                >
                  Place Order
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {redeemLoader && <Loader />}
    </div>
  );
};

export default BillingForm;
