import { FC } from "react";
import Arrow from "../../../common/Package/Dashboard/arrow.svg";

interface CardProps {
  image: string;
  title: string;
  price: number;
  border: string;
  bgColor: string;
  pageId?: string;
  buttonName?: string;
  coinImage?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  subPageNavigation?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}

const Card: FC<CardProps> = (props) => {
  const handleOnclick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (props.onClick) {
      props.onClick(e);
    }

    if (props.subPageNavigation) {
      props.subPageNavigation(e);
    }
  };

  return (
    <div className="card md:w-[25em] w-[11rem] font-bold md:mt-7 mt-4">
      <div
        style={{ borderColor: props.border, backgroundColor: "#fff" }}
        className="border md:p-3 pt-3 pb-1 rounded-md relative"
      >
        <div className="flex justify-center">
          <img
            style={{
              backgroundColor: props.bgColor,
              borderColor: props.border,
            }}
            className="rounded-full w-[10vh] h-[10vh] md:-mt-[8vh] p-3 border z-0 md:absolute"
            src={props.image}
            alt="icons"
          />
        </div>
        <div className="flex flex-col justify-center items-center">
          <div className="md:text-[2.5vh] text-[2vh] md:mt-8 mt-3 md:mb-1">
            {props.title}
          </div>
          <div className="flex md:flex-col flex-row justify-center items-center w-[100%]">
            <div className="flex flex-row justify-center items-center text-center md:ml-0 ml-8 my-[2vh] w-[60%]">
              <div className="w-[2rem] inline-block md:hidden h-[100%]">
                {props.coinImage && <img src={props.coinImage} alt="coins" />}
              </div>

              <p className="md:text-[4vh] text-[3.5vh] text-center">
                {props.price ? props.price : 0}
              </p>
            </div>
            <button
              onClick={handleOnclick}
              className="border-2 my-3 border-primary text-primary rounded p-2 md:block hidden"
            >
              {props?.buttonName}
            </button>
            <div className="w-[40%]">
              <button className="block md:hidden pl-6 mt-[0.3rem]">
                <a href={`#/${props.pageId}`}>
                  <img src={Arrow} className="w-[0.5rem]" />
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
