import { FC, useEffect, useState } from "react";
import RedeemFailureStyles from "./RedeemFailure.styles";
import { RedeemPageProps } from "../../../../models/RedeemProps";
import { usePopup } from "../../../../api/PopupService";
import { useWallet } from "../../Context/Wallet";
import { getSasToken } from "../../../../api/LeaderBoardService";
import { env } from "../../../../env/env";
import { Close } from "../../../../common/icons/Close";
import { navigateBetweenTabs } from "../../../../api/NavigationService";

const RedeemFailure: FC<RedeemPageProps> = (props) => {
  const { closePopup } = usePopup();
  const [sasToken, setSasToken] = useState<string>("");
  const { wallet } = useWallet();

  const onCloseButtonClick = () => {
    closePopup();
  };

  useEffect(() => {
    getSasToken()
      .then((sasToken: string) => {
        setSasToken(sasToken);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [props.walletData]);

  const handleDefaultCoinImg = (e) => {
    e.target.src = `${env.BLOB_URL}/${env.CONTAINER_PATH}/coins.png`;
  };

  const handleNavigation = () => {
    onCloseButtonClick();
    props.navigationToRewards();
  };

  return (
    <RedeemFailureStyles.FailureModal>
      <div className="modal-content">
        <div>
          <header>
            <div className="cancel" onClick={onCloseButtonClick}>
              <Close />
            </div>
          </header>
          <div>
            <div className="failure-details">
              <div>
                <div className="flex justify-center items-center">
                  <img
                    alt="failure"
                    className="failure"
                    src={`${env.BLOB_URL}/${env.CONTAINER_PATH}/failure.jpg`}
                  />
                </div>
                <div className="md:text-lg text-base font-bold">Oops !</div>
                <div className="md:text-lg text-base my-5 font-medium">
                  <div>Your Order has been failed.</div>
                </div>
              </div>
            </div>
            <div className="order-description mt-5">
              <div className="md:text-base text-sm font-medium text-center">
                Your current balance is
              </div>
              <div className="flex justify-center items-center balance-debited my-3">
                <img
                  alt="coin"
                  className="w-[1.5em] h-[1.5em]"
                  src={wallet?.imageUrl + sasToken}
                  onError={(e) => handleDefaultCoinImg(e)}
                />
                &nbsp;&nbsp;
                <div>{wallet?.balance || 0 + " coins"}</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center w-full gap-3 mt-10">
            <button
              onClick={onCloseButtonClick}
              className="bg-primary w-[10em] rounded-md text-white p-2 text-xs"
            >
              Try again
            </button>
            <button
              onClick={navigateBetweenTabs("dashboard")}
              className="border-primary w-[10em] border-2 text-primary text-xs rounded-md  p-2 mt-1 md:block hidden"
            >
              Go to My Wallet
            </button>
            <button onClick={() => onCloseButtonClick()}>
              <a
                href="#/dashboard"
                className="border-primary w-[10em] border-2 text-primary text-xs rounded-md  p-2 mt-1 md:hidden block"
              >
                Go to My Wallet
              </a>
            </button>
          </div>
        </div>
      </div>
    </RedeemFailureStyles.FailureModal>
  );
};

export default RedeemFailure;
