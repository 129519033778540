import { FC, useContext, useEffect, useState } from "react";
import { useWallet } from "./Context/Wallet";
import { getWalletBalance } from "../../api/RedeemService";
import { Navigate, Route, Routes } from "react-router-dom";
import Privacy from "../Privacy";
import TermsOfUse from "../TermsOfUse";
import Dashboard from "./Dashboard/Dashboard";
import RewardsTab from "./RewardsTab/RewardsTab";
import RedeemTab from "./RedeemTab/RedeemTab";
import RecognitionSent from "./Recognition/RecognitionSent";
import ProductDetail from "./Redeem/ProductDetail";
import BillingForm from "./Redeem/BillingForm";
import { executeUserFlow } from "../../api/User.service";
import { TeamsFxContext } from "../Context";
import { useData } from "@microsoft/teamsfx-react";
import { useUser } from "./Context/User";
import { useSelector } from "react-redux";
import { RootState } from "../../context/store";

const Layout: FC = () => {
  const applicationId = useSelector(
    (state: RootState) => state.application.applicationId
  );

  const { setWallet } = useWallet();
  const [checkUserExist, setCheckUserExist] = useState<boolean>(false);
  const { setUserId } = useUser();
  const { teamsUserCredential } = useContext(TeamsFxContext);
  const { loading, data, error } = useData(async () => {
    if (teamsUserCredential) {
      const userInfo = await teamsUserCredential.getUserInfo();
      return userInfo;
    }
  });
  const userName = loading || error ? "" : data?.displayName || "";
  const userEmail = loading || error ? "" : data?.preferredUserName || "";

  useEffect(() => {
    if (!loading && userEmail) {
      userFlowCheck(userEmail, userName);
      setUserId(userEmail);
    }
  }, [loading, userEmail, userName, applicationId]);

  const userFlowCheck = async (email: string, name: string) => {
    if (applicationId) {
      const currentUserFlow = await executeUserFlow(email, name, applicationId);
      setCheckUserExist(currentUserFlow);
    }
  };

  useEffect(() => {
    if (applicationId) {
      getWalletBalance(applicationId, userEmail)
        .then((walletData: any) => {
          if (walletData.balance && walletData.balance.length > 0) {
            setWallet(walletData.balance[0]);
          }
        })
        .catch((error) => {
          console.error("Error fetching wallet data:", error);
        });
    }
  }, [applicationId]);

  return (
    <div>
      <div>
        {checkUserExist ? (
          <Routes>
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/termsofuse" element={<TermsOfUse />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/rewards" element={<RewardsTab />} />
            <Route path="/redeem" element={<RedeemTab />} />
            <Route path="/coin-recognized" element={<RecognitionSent />} />
            <Route path="*" element={<Navigate to={"/dashboard"} />} />
            <Route path="/detail-product" element={<ProductDetail />} />
            <Route path="/billing-form/" element={<BillingForm />} />
          </Routes>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Layout;
