import * as microsoftTeams from "@microsoft/teams-js";
import { pages } from "@microsoft/teams-js";

let app = microsoftTeams.app;

export const navigateBetweenTabs =
  (pageId: string) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    app
      .initialize()
      .then(app.getContext)
      .then((context: any) => {
        if (pages.currentApp.isSupported()) {
          pages.currentApp.navigateTo({
            pageId: pageId,
          });
        } else {
          pages.currentApp.navigateToDefaultPage();
        }
      });
  };
