import { FC, useContext, useEffect, useState } from "react";
import { getUserInfos } from "../../../api/LeaderBoardService";
import { TeamsFxContext } from "../../Context";
import { useData } from "@microsoft/teamsfx-react";
import { useSelector } from "react-redux";
import { RootState } from "../../../context/store";

interface ProgressBarProps {}

const ProgressBar: FC<ProgressBarProps> = () => {
  const applicationId = useSelector(
    (state: RootState) => state.application.applicationId
  );

  const [userDetails, setUserDetails] = useState<any>();
  const [userSpent, setUserSpent] = useState<any>();
  const [daysUntilMonthEnd, setDaysUntilMonthEnd] = useState<number>(0);
  const { teamsUserCredential } = useContext(TeamsFxContext);
  const { loading, data, error } = useData(async () => {
    if (teamsUserCredential) {
      const userInfo = await teamsUserCredential.getUserInfo();
      return userInfo;
    }
  });
  const userEmail = loading || error ? "" : data!.preferredUserName;

  const calculateDaysUntilMonthEnd = () => {
    const today = new Date();
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const diffTime = endOfMonth.getTime() - today.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    setDaysUntilMonthEnd(diffDays);
  };

  useEffect(() => {
    if (!loading && userEmail) {
      calculateDaysUntilMonthEnd();
      getUserInfos(userEmail, applicationId)
        .then((userInfo) => {
          setUserDetails(userInfo?.data?.data);
          setUserSpent(
            userInfo?.data?.data?.recognitionLimit -
              userInfo?.data?.data?.recognitionBalance
          );
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [userEmail]);

  return (
    <div>
      <div className="font-bold text-sm">
        Total: {userDetails?.recognitionLimit} coins
      </div>
      <div className="font-normal text-gray-600 md:my-3 my-2">
        Validity remains for {daysUntilMonthEnd} days
      </div>
      <div className="progress-bar bg-[#e7d7f8] flex gap-12 md:h-3 h-2 rounded-full">
        {userSpent ? (
          <div
            style={{ width: `${userSpent}%` }}
            className={`text-center text-nowrap bg-[#663399] rounded-full`}
          ></div>
        ) : (
          <></>
        )}
      </div>

      <div className="text-sm font-bold text-[#663399] md:mt-3 mt-2">
        {userDetails?.recognitionBalance} Coins Left
      </div>
    </div>
  );
};

export default ProgressBar;
