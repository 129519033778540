import { FC, useEffect, useRef, useState } from "react";
import WalletHistory from "../WalletHistory/WalletHistory";
import RecognitionReceived from "../Recognition/RecognitionReceived";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Calendar from "../../../common/Package/Recognition/calendar.svg";
import { useLocation } from "react-router-dom";
import { getSubPage, setSubPage } from "../../../api/subPage.service";
interface RewardTabProps {}

const RewardTab: FC<RewardTabProps> = () => {
  const [activeTab, setActiveTab] = useState("recognitionReceived");
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);
  const today = new Date();
  const lastWeek = new Date();
  lastWeek.setDate(today.getDate() - 6);
  const [fromDate, setFromDate] = useState<Date>(lastWeek);
  const [toDate, setToDate] = useState<Date>(today);
  const location = useLocation();

  const datePickerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target as Node)
      ) {
        setIsDatePickerOpen(false);
      }
    };

    const subPageId = getSubPage();
    if (subPageId === "walletHistory") {
      setActiveTab(subPageId);
      setSubPage("");
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [location]);

  const handleDateChange = (ranges: any) => {
    const { startDate, endDate } = ranges.selection;
    setFromDate(startDate);
    setToDate(endDate);
  };

  return (
    <div className="md:bg-[#eeeeee] bg-white md:min-h-screen h-[100dvh]">
      <div className="header bg-white md:w-[98%] w-[100%] m-auto px-2 py-1 font-bold text-lg flex justify-between items-center">
        <div>Rewards</div>
        <div>
          <div
            className="md:border p-2 rounded flex relative"
            ref={datePickerRef}
          >
            <div
              className="text-sm cursor-pointer flex gap-2 justify-center items-center"
              onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
            >
              <div className="md:block hidden">
                {fromDate.toLocaleDateString("fr-CA")} -{" "}
                {toDate.toLocaleDateString("fr-CA")}
              </div>
              <img src={Calendar} alt="Calendar" />
            </div>
            {isDatePickerOpen && (
              <div className="absolute right-0 top-14 z-50">
                <DateRange
                  ranges={[
                    {
                      startDate: fromDate,
                      endDate: toDate,
                      key: "selection",
                    },
                  ]}
                  rangeColors={["#663399"]}
                  maxDate={new Date()}
                  onChange={handleDateChange}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center md:h-[93%] h-[85%] m-auto">
        <div className="content md:w-[78%] w-[100%] m-auto md:pb-3">
          <div className="flex justify-start pl-[0.3rem] md:pl-0 py-[2vh] bg-[#eeeeee]">
            <button
              className={`rounded-md px-3 py-2 font-semibold text-xs ${
                activeTab === "recognitionReceived"
                  ? "bg-primary text-white font-semibold"
                  : "bg-white text-[#AD8FCA] border border-[#EDF]"
              }`}
              onClick={() => setActiveTab("recognitionReceived")}
            >
              Recognition Received
            </button>
            <button
              className={`mx-4 rounded-md px-3 py-2 font-semibold text-xs ${
                activeTab === "walletHistory"
                  ? "bg-primary text-white"
                  : "bg-white text-[#AD8FCA]  border border-[#EDF]"
              }`}
              onClick={() => setActiveTab("walletHistory")}
            >
              Wallet History
            </button>
          </div>
          {activeTab === "walletHistory" ? (
            <WalletHistory fromDate={fromDate} toDate={toDate} />
          ) : (
            <RecognitionReceived fromDate={fromDate} toDate={toDate} />
          )}
        </div>
      </div>
    </div>
  );
};

export default RewardTab;
