import { FC, useEffect, useState } from "react";
import { env } from "../../../env/env";
import { getSasToken } from "../../../api/LeaderBoardService";
import { Link, useLocation } from "react-router-dom";
import { useProduct } from "../Context/Product";
import { useWallet } from "../Context/Wallet";

const ProductDetail: FC = () => {
  const [sasToken, setSasToken] = useState<string>("");
  const { product } = useProduct();
  const { wallet } = useWallet();
  const location = useLocation();
  const coinErrorHandler = (e) => {
    e.target.src = `${env.BLOB_URL}/${env.CONTAINER_PATH}/coins.png`;
  };

  useEffect(() => {
    getSasToken()
      .then((sasToken: string) => {
        setSasToken(sasToken);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const getBreadcrumb = () => {
    switch (location.pathname) {
      case "/detail-product":
        return (
          <>
            <Link to="/redeem">List of Coupons</Link> &gt;{" "}
            <span className="text-[#663399]">Redeem now</span>
          </>
        );
      default:
        return "List of Coupons";
    }
  };

  return (
    <div className="md:min-h-screen">
      <div className="header bg-white md:w-[98%] w-[100%] m-auto p-2 font-bold md:text-lg text-sm flex justify-between px-6">
        <div className="border-r-2 pr-3 font-[400]">{getBreadcrumb()}</div>
        <div className="flex gap-3 items-center">
          <img
            className="w-[1.5em] h-[1.5em] rounded-full"
            src={wallet?.imageUrl + sasToken}
            alt=""
            onError={(e) => coinErrorHandler(e)}
          />
          <div>{wallet?.balance || 0 + " coins"}</div>
        </div>
      </div>
      <div className="my-6 flex justify-center items-center">
        <div className="bg-white rounded p-4 md:w-1/3 w-full">
          <section>
            <img
              className="h-[20em] w-full"
              src={product?.productImageUrl + sasToken}
              alt=""
            />
            <div className="details ml-3">
              <div>
                <h2 className="mb-2 font-bold text-lg">
                  {product?.productName} -{" "}
                  <span>&#x20B9;{product?.transactionAmount}</span>
                </h2>
                <div className="coin flex items-center gap-2 my-2 ">
                  <img
                    alt="virtual-money-image"
                    className="w-[1.5em] h-[1.5em] rounded-full"
                    src={product?.virtualMoneyImageUrl + sasToken}
                  />
                  <span>{product?.eligibleMoney + " coins"}</span>
                </div>
              </div>
              <Link to={`/billing-form/`}>
                <button className="bg-primary rounded-md text-white p-2 mt-5">
                  Redeem Now
                </button>
              </Link>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
