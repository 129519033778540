import { FC, useContext, useEffect, useRef, useState } from "react";
import { DateRange } from "react-date-range";
import RecognitionImage from "../../../common/Package/Recognition/rr.svg";
import { getUserInfos } from "../../../api/LeaderBoardService";
import PreviousIcon from "../../../common/Package/WalletHistory/previous.svg";
import NextIcon from "../../../common/Package/WalletHistory/next.svg";
import ReactPaginate from "react-paginate";
import { getRecognitionHistory } from "../../../api/recognition.service";
import "./Recognition.css";
import ProgressBar from "./ProgressBar";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Calendar from "../../../common/Package/Recognition/calendar.svg";
import { TeamsFxContext } from "../../Context";
import { useData } from "@microsoft/teamsfx-react";
import Loader from "../Core/Loader";
import { useSelector } from "react-redux";
import { RootState } from "../../../context/store";
import NoDataFound from "../../../common/Package/Recognition/no-data-found.svg";
import dayjs from "dayjs";
interface RecognitionSentProps {}

const RecognitionSent: FC<RecognitionSentProps> = () => {
  const [userRecognitionsHistory, setUserRecognitionsHistory] = useState<any>(
    []
  );
  const applicationId = useSelector(
    (state: RootState) => state.application.applicationId
  );
  const [loader, setLoader] = useState(false);
  const [userSpent, setUserSpent] = useState<any>();
  const [pageCount, setPageCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);
  const today = new Date();
  const lastWeek = new Date();
  lastWeek.setDate(today.getDate() - 6);
  const [fromDate, setFromDate] = useState<Date>(lastWeek);
  const [toDate, setToDate] = useState<Date>(today);
  const datePickerRef = useRef<HTMLDivElement>(null);
  const { teamsUserCredential } = useContext(TeamsFxContext);
  const { loading, data, error } = useData(async () => {
    if (teamsUserCredential) {
      const userInfo = await teamsUserCredential.getUserInfo();
      return userInfo;
    }
  });
  const userEmail = loading || error ? "" : data!.preferredUserName;

  useEffect(() => {
    if (!loading && userEmail) {
      getUserInfos(userEmail, applicationId)
        .then((userInfo) => {
          setUserSpent(userInfo?.data?.data?.recognitionBalance);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [userEmail]);

  useEffect(() => {
    userRecognitionDetails(pageNumber, fromDate, toDate);
  }, [pageNumber, fromDate, toDate, userEmail]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target as Node)
      ) {
        setIsDatePickerOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const userRecognitionDetails = async (
    page: number,
    fromDate: Date,
    toDate: Date
  ) => {
    setLoader(true);
    let filter = {
      recognitionType: "sent",
      userId: userEmail,
      application: applicationId,
      fromDate: dayjs(fromDate).format("YYYY-MM-DD:00:00Z"),
      toDate: dayjs(toDate).format("YYYY-MM-DD:23:59Z"),
      pageSize: 3,
      pageNumber: page,
    };

    try {
      const response: any = await getRecognitionHistory(filter);
      if (response) {
        setLoader(false);
        setUserRecognitionsHistory(response?.recognitions);
        setPageCount(Math.ceil(response?.totalCount / filter.pageSize));
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const handlePageClick = (data: any) => {
    const selectedPage = data.selected + 1;
    setPageNumber(selectedPage);
  };

  const handleDateChange = (ranges: any) => {
    const { startDate, endDate } = ranges.selection;
    setFromDate(startDate);

    setToDate(endDate);
  };

  const formatDateTime = (dateString: string): string => {
    const date = new Date(dateString);
    return dayjs(date).format("DD MMM, hh:mm a");
  };

  return (
    <div className="min-h-[100vh] pb-6 bg-white md:bg-transparent">
      <div className="header bg-white md:w-[98%] w-[100%] m-auto p-2 font-bold text-lg flex justify-between">
        <div className="md:border-r-2 pr-3">Recognition Sent</div>
        <div
          className="md:border p-2 rounded flex relative"
          ref={datePickerRef}
        >
          <div
            className="text-sm cursor-pointer flex gap-2 justify-center items-center"
            onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
          >
            <div className="md:flex hidden">
              {fromDate.toLocaleDateString("fr-CA")} -{" "}
              {toDate.toLocaleDateString("fr-CA")}
            </div>
            <img src={Calendar} alt="calendar" />
          </div>
          {isDatePickerOpen && (
            <div className="absolute right-0 top-14 z-50">
              <DateRange
                ranges={[
                  {
                    startDate: fromDate,
                    endDate: toDate,
                    key: "selection",
                  },
                ]}
                rangeColors={["#663399"]}
                maxDate={new Date()}
                onChange={handleDateChange}
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-center items-center h-[89%]">
        <div className="md:w-[80%] w-[100%] mx-auto md:mt-[1.5em] bg-white">
          <div className="md:bg-[#FCF9FF] px-5 pt-5">
            <div className="header flex justify-between items-center bg-white md:px-4 py-0">
              <div className="w-full ">
                <ProgressBar />
              </div>
            </div>
          </div>
          <div className="recognition-sent-history md:px-4 px-2 pr-4 md:pr-4 pt-9 pb-3 h-[60vh] my-3 overflow-auto">
            {userRecognitionsHistory.length > 0 ? (
              userRecognitionsHistory.map((recognition) => (
                <div key={recognition.id}>
                  <div className="flex justify-start my-1 gap-2 ">
                    <div className="flex flex-col items-center">
                      <img
                        alt="RecognitionImage"
                        src={RecognitionImage}
                        className="-mt-[0.5rem] max-w-[90%]"
                      />
                      <div className="border-l-2 border-primary border-dashed h-full"></div>
                    </div>
                    <div className="w-full pb-[2vh]">
                      <div className="flex items-center justify-between mb-2 gap-3">
                        <div>
                          <span className="coin rounded-full py-2 px-3 text-[#BF8300] bg-[#FEF9EE] font-[700]">
                            - {recognition.count}
                          </span>
                          <span className="px-2 text-base font-[500]">
                            {recognition.senderUserName}
                          </span>
                        </div>
                        <div className="text-[0.75rem] font-[500]">
                          {formatDateTime(recognition.createdAt)}
                        </div>
                      </div>
                      <div className="bg-[#E8EBFA] rounded-md md:p-2 p-1 pl-4">
                        <div className="md:my-2 my-1 text-[#663399] font-[600] text-[1rem]">
                          To{" "}
                          <span className="text-black font-[600]">
                            {recognition.receiverUserName}:{" "}
                          </span>
                        </div>
                        <div className="text-[#5B5FC6] md:my-2 my-1 text-[0.85rem] leading-[1.25rem] font-[500]">
                          {recognition.message}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="flex justify-center items-center h-[100%]">
                <img
                  src={NoDataFound}
                  alt="No data found"
                  className="md:w-[30%] w-[50%]"
                />
              </div>
            )}
          </div>
          {pageCount > 1 && (
            <div className="flex md:justify-end justify-center">
              <ReactPaginate
                previousLabel={
                  <img
                    className="-mt-5 mx-2"
                    src={PreviousIcon}
                    alt="Previous"
                  />
                }
                nextLabel={
                  <img className="-mt-5 mx-2" src={NextIcon} alt="Next" />
                }
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={4}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                previousClassName={"previous"}
                nextClassName={"next"}
              />
            </div>
          )}
        </div>
      </div>
      {loader && <Loader />}
    </div>
  );
};

export default RecognitionSent;
