import { FC, useContext, useEffect, useState } from "react";
import homeBanner from "../../../common/Package/Dashboard/home-banner.svg";
import Card from "./Card";
import { getUserRecognitionDetails } from "../../../api/recognition.service";
import walletImage from "../../../common/Package/Dashboard/wallet.svg";
import coinImage from "../../../common/Package/Dashboard/gold.svg";
import RecognitionReceived from "../../../common/Package/Dashboard/recognition-received.svg";
import RecognitionSent from "../../../common/Package/Dashboard/recognition-sent.svg";
import CouponRedeemed from "../../../common/Package/Dashboard/coins-redeemed.svg";
import TotalCoins from "../../../common/Package/Dashboard/coins-total.svg";
import CoinsLeft from "../../../common/Package/Dashboard/coins-left.svg";
import { useWallet } from "../Context/Wallet";
import { getTransactionHistory } from "../../../api/RedeemService";
import { executeUserFlow } from "../../../api/User.service";
import { TeamsFxContext } from "../../Context";
import { useData } from "@microsoft/teamsfx-react";
import { useUser } from "../Context/User";
import Loader from "../Core/Loader";
import { useSelector } from "react-redux";
import { RootState } from "../../../context/store";
import { navigateBetweenTabs } from "../../../api/NavigationService";
import { setSubPage } from "../../../api/subPage.service";

interface DashboardProps {}

const Dashboard: FC<DashboardProps> = () => {
  let [checkUserExist, setCheckUserExist] = useState<boolean>(false);
  const { setUserId } = useUser();
  const { wallet } = useWallet();
  const applicationId = useSelector(
    (state: RootState) => state.application.applicationId
  );

  const [userRecognitions, setUserRecognitions] = useState<any>([]);
  const [couponRedeemed, setCouponRedeemed] = useState<number | undefined>();
  const [loader, setLoader] = useState(false);
  const { teamsUserCredential } = useContext(TeamsFxContext);
  const { loading, data, error } = useData(async () => {
    if (teamsUserCredential) {
      const userInfo = await teamsUserCredential.getUserInfo();
      return userInfo;
    }
  });
  const userName = loading || error ? "" : data?.displayName || "";
  const userEmail = loading || error ? "" : data?.preferredUserName || "";

  useEffect(() => {
    if (!loading && userEmail) {
      userFlowCheck(userEmail, userName);
      setUserId(userEmail);
    }
  }, [loading, userEmail, userName]);

  const userFlowCheck = async (email: string, name: string) => {
    if (applicationId) {
      const currentUserFlow = await executeUserFlow(email, name, applicationId);
      setCheckUserExist(currentUserFlow);
      setUserDetails(email);
    }
  };

  const setUserDetails = async (userId: string) => {
    if (applicationId) {
      setLoader(true);
      getTransactionHistory(userId, applicationId)
        .then((responseData: any) => {
          setLoader(false);
          setCouponRedeemed(responseData.length);
        })
        .catch((error) => {
          setLoader(false);
          console.error("Error fetching transaction history:", error);
        });
      userRecognitionDetails(userId);
    } else {
      console.error("Application ID is not available.");
    }
  };

  const userRecognitionDetails = async (userId: string) => {
    try {
      const response: any = await getUserRecognitionDetails(userId);
      if (response) {
        setUserRecognitions(response);
      }
    } catch (error) {
      console.error("Error fetching user recognition details:", error);
    }
  };

  const createSubPageNavigationHandler = (subPageId: string) => {
    return (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      setSubPage(subPageId);
    };
  };

  return (
    <div>
      {checkUserExist ? (
        <div className="Dashboard" data-testid="Dashboard">
          <div className="max-w-[1100px] min-h-[100vh] m-auto md:pt-0 pt-[2vh] pb-[1em] grid">
            <div className="w-full grid">
              <div className="banner flex justify-center items-center relative w-full">
                <img
                  src={homeBanner}
                  alt="home-banner"
                  className="md:h-[33vh] h-[27vh] object-cover md:w-[99%] w-[95%] mx-auto bg-[#663399] rounded-md block"
                />
                <div className="absolute top-4 text-center text-white">
                  <div className="md:text-[1.75em] text-[1.25em] md:mt-[6vh] mt-[1vh] md:mb-[4vh] mb-[2vh]">
                    Welcome
                  </div>
                  <div className="md:text-[3em] text-[1.75em] font-bold">
                    {userName}
                  </div>
                </div>
              </div>
              <div className="grid md:grid-cols-3 grid-cols-2 md:grid-rows-2 grid-rows-3 md:-mt-[7vh] w-[100%] place-items-center">
                <Card
                  image={walletImage}
                  title={"Wallet"}
                  price={wallet?.balance}
                  border={"#CB5787"}
                  bgColor={"#F8E1EA"}
                  pageId={"redeem"}
                  buttonName={"Redeem your coins"}
                  coinImage={coinImage}
                  onClick={navigateBetweenTabs("redeem")}
                />
                <Card
                  image={CoinsLeft}
                  title={"Coins Left"}
                  price={userRecognitions?.recognitionBalance}
                  border={"#567CC4"}
                  bgColor={"#ECF3FF"}
                  pageId={"coin-recognized"}
                  buttonName={"View Details"}
                  coinImage={coinImage}
                  onClick={navigateBetweenTabs("coin-recognized")}
                />
                <Card
                  image={TotalCoins}
                  title={"Total Coins"}
                  price={userRecognitions?.totalCoinsReceived}
                  border={"#199644"}
                  bgColor={"#E2FFEC"}
                  pageId={"rewards"}
                  buttonName={"View Balance Details"}
                  coinImage={coinImage}
                  onClick={navigateBetweenTabs("rewards")}
                />
                <Card
                  image={RecognitionSent}
                  title={"Recognition sent"}
                  price={userRecognitions?.recognitionSent}
                  border={"#DD8C4A"}
                  bgColor={"#F9F3EF"}
                  pageId={"coin-recognized"}
                  buttonName={"View Details"}
                  coinImage={null}
                  onClick={navigateBetweenTabs("coin-recognized")}
                />
                <Card
                  image={RecognitionReceived}
                  title={"Recognition Received"}
                  price={userRecognitions?.recognitionReceived}
                  border={"#7AB4BC"}
                  bgColor={"#E1F6F5"}
                  pageId={"rewards"}
                  buttonName={"View Details"}
                  coinImage={null}
                  onClick={navigateBetweenTabs("rewards")}
                  subPageNavigation={createSubPageNavigationHandler(
                    "recognitionReceived"
                  )}
                />
                <Card
                  image={CouponRedeemed}
                  title={"Coupon Redeemed"}
                  price={couponRedeemed}
                  border={"#B39C62"}
                  bgColor={"#F6F4E9"}
                  pageId={"redeem"}
                  buttonName={"View Coupon Details"}
                  coinImage={null}
                  onClick={navigateBetweenTabs("redeem")}
                  subPageNavigation={createSubPageNavigationHandler(
                    "couponHistory"
                  )}
                />
              </div>
            </div>
          </div>
          {loader && <Loader />}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Dashboard;
