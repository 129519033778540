import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ApplicationState {
  applicationId: string | null;
}

const initialState: ApplicationState = {
  applicationId: null,
};

const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setApplicationId(state, action: PayloadAction<string | null>) {
      state.applicationId = action.payload;
    },
  },
});

export const { setApplicationId } = applicationSlice.actions;

export default applicationSlice.reducer;
