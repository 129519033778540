import {
  FluentProvider,
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  Spinner,
  tokens,
} from "@fluentui/react-components";
import { HashRouter as Router } from "react-router-dom";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import { TeamsFxContext } from "./Context";
import config from "./sample/lib/config";
import { UserProvider } from "./Package/Context/User";
import Layout from "./Package/Layout";
import * as microsoftTeams from "@microsoft/teams-js";
import { useEffect, useState } from "react";
import {
  getAccessTokenByAppSpecificId,
  initializeAppSpecificId,
} from "../api/TokenService";
import { getApplicationByAppSpecificId } from "../api/ApplicationService";
import { useDispatch } from "react-redux";
import { setApplicationId } from "../context/applicationSlice";
import Loader from "./Package/Core/Loader";
import Initialize from "./Initialize";

export default function App() {
  const app = microsoftTeams.app;

  const dispatch = useDispatch();
  const { loading, theme, themeString, teamsUserCredential } =
    useTeamsUserCredential({
      initiateLoginEndpoint: config.initiateLoginEndpoint!,
      clientId: config.clientId!,
    });

  const [isTokenRetrieved, setIsTokenRetrieved] = useState<boolean | null>(
    null
  );

  useEffect(() => {
    const initializeApp = async () => {
      try {
        const context = await app.initialize().then(app.getContext);
        await initializeAppSpecificId(context?.user?.tenant?.id || "");
        await getAccessTokenByAppSpecificId();
        setIsTokenRetrieved(true);
        const application = await getApplicationByAppSpecificId(
          context?.user?.tenant?.id || ""
        );
        if (application?._id) {
          dispatch(setApplicationId(application._id));
        }
      } catch (error) {
        console.error("Error initializing app:", error);
        setIsTokenRetrieved(false);
      }
    };

    initializeApp();
  }, [app]);

  return (
    <TeamsFxContext.Provider
      value={{ theme, themeString, teamsUserCredential }}
    >
      <UserProvider>
        <FluentProvider
          theme={
            themeString === "dark"
              ? teamsDarkTheme
              : themeString === "contrast"
              ? teamsHighContrastTheme
              : {
                  ...teamsLightTheme,
                  colorNeutralBackground3: "#eeeeee",
                }
          }
          style={{ background: tokens.colorNeutralBackground3 }}
        >
          <div className={` ${themeString === "dark" ? "text-black" : ""}`}>
            <Router>
              {loading ? (
                <Loader />
              ) : isTokenRetrieved === null ? (
                <Loader />
              ) : !isTokenRetrieved ? (
                <div>
                  <Initialize />
                </div>
              ) : (
                <Layout />
              )}
            </Router>
          </div>
        </FluentProvider>
      </UserProvider>
    </TeamsFxContext.Provider>
  );
}
