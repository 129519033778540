import { FC, useEffect, useState } from "react";
import { Close } from "../../../../common/icons/Close";
import RedeemConfirmStyles from "./RedeemConfirmation.styles";
import { RedeemPageProps } from "../../../../models/RedeemProps";
import { env } from "../../../../env/env";
import { getSasToken } from "../../../../api/LeaderBoardService";
import { usePopup } from "../../../../api/PopupService";
import { getWalletBalance } from "../../../../api/RedeemService";
import SuccessImage from "../../../../common/Package/Redeem/success.svg";
import { useWallet } from "../../../Package/Context/Wallet";
import { IWallet } from "../../../../models/Balance";
import { navigateBetweenTabs } from "../../../../api/NavigationService";

const RedeemConfirmation: FC<RedeemPageProps> = (props) => {
  const { closePopup } = usePopup();
  const { wallet, setWallet } = useWallet();
  const [sasToken, setSasToken] = useState<string>("");

  useEffect(() => {
    getSasToken()
      .then((sasToken: string) => {
        setSasToken(sasToken);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    getWalletBalance(props.applicationId, props.userId)
      .then((walletData: IWallet) => {
        setWallet(walletData.balance[0]);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [props.walletData]);
  const handleDefaultCoinImg = (e) => {
    e.target.src = `${env.BLOB_URL}/${env.CONTAINER_PATH}/coins.png`;
  };
  const handleClosePopup = () => {
    closePopup();
  };
  const handleNavigationToRewards = () => {
    handleClosePopup();
    props.navigationToRewards();
  };

  const handleNavigationToProducts = () => {
    handleClosePopup();
    props.navigationToProducts();
  };

  return (
    <RedeemConfirmStyles.ConfirmModal>
      <div className="redeem-confirm modal-content">
        <header>
          <div
            className="cancel -mt-[1rem] -ml-[1rem]"
            onClick={handleClosePopup}
          >
            <Close />
          </div>
        </header>
        <div>
          <div className="success-details">
            <div className="flex flex-col items-center justify-center">
              <div>
                <div className="flex justify-center -mt-4">
                  <img
                    alt="success"
                    className="success md:max-w-[100%] max-w-[70%]"
                    src={SuccessImage}
                  />
                </div>
                <div className="md:text-lg text-base font-bold">
                  Order placed Successfully
                </div>
              </div>
              <div className="order-description mt-10 w-[95%]">
                <div className="md:text-base text-sm font-medium">
                  {props.product?.eligibleMoney || 0} coins Debited from your
                  wallet and your current balance is
                </div>
                <div className="balance-debited my-3">
                  <img
                    alt="coin"
                    className="wallet-coin"
                    src={wallet?.imageUrl + sasToken}
                    onError={(e) => handleDefaultCoinImg(e)}
                  />
                  &nbsp;&nbsp;
                  <div className="text-base font-bold">
                    {wallet?.balance || 0} Coins
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-center items-center w-full gap-2 mt-7">
            <button
              onClick={handleNavigationToProducts}
              className="bg-primary w-[10em] rounded-md text-white p-2 text-xs block"
            >
              Redeem more
            </button>
            <button
              onClick={navigateBetweenTabs("dashboard")}
              className="border-primary w-[10em] border-2 text-primary rounded-md p-1.5 mt-1 text-xs md:block hidden"
            >
              Go to my wallet
            </button>
            <button onClick={() => handleClosePopup()}>
              <a
                href="#/dashboard"
                className="border-primary w-[10em] border-2 text-primary rounded-md p-1.5 mt-1 text-xs block md:hidden"
              >
                Go to my wallet
              </a>
            </button>
          </div>
        </div>
      </div>
    </RedeemConfirmStyles.ConfirmModal>
  );
};

export default RedeemConfirmation;
