import {
  createContext,
  useState,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";
interface WalletContextType {
  wallet: any;
  setWallet: Dispatch<SetStateAction<any>>;
}

const WalletContext = createContext<WalletContextType>({
  wallet: null,
  setWallet: () => {},
});

export const WalletProvider = ({ children }: { children: ReactNode }) => {
  const [wallet, setWallet] = useState<any>(null);

  return (
    <WalletContext.Provider value={{ wallet, setWallet }}>
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = () => useContext(WalletContext);
