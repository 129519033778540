import { FC, useEffect, useState } from "react";
import CouponRedeemed from "../WalletHistory/CouponRedeemed";
import RedeemableProductList from "../Redeem/RedeemableProductList";
import { useWallet } from "../Context/Wallet";
import { getSasToken } from "../../../api/LeaderBoardService";
import { env } from "../../../env/env";
import * as microsoftTeams from "@microsoft/teams-js";
import { getSubPage, setSubPage } from "../../../api/subPage.service";

let app = microsoftTeams.app;

interface RedeemTabProps {}

const RedeemTab: FC<RedeemTabProps> = () => {
  const { wallet } = useWallet();
  const [activeTab, setActiveTab] = useState("redeemProduct");
  const [sasToken, setSasToken] = useState<string>("");
  useEffect(() => {
    getSasToken()
      .then((sasToken: string) => {
        setSasToken(sasToken);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    const subPageId = getSubPage();
    if (subPageId === "couponHistory") {
      setActiveTab(subPageId);
      setSubPage("");
    }
  }, []);

  const handleDefaultCoinImg = (e) => {
    e.target.src = `${env.BLOB_URL}/${env.CONTAINER_PATH}/coins.png`;
  };

  return (
    <div className="md:bg-[#eeeeee] bg-white min-h-[100vh]" data-testid="App">
      <div className="header bg-white md:w-[98%] w-[100%] m-auto p-2 font-bold text-lg flex justify-between">
        <div>List of Coupons</div>
        <div className="flex gap-3 items-center">
          <img
            className="w-[1.5em] h-[1.5em] rounded-full"
            src={wallet?.imageUrl + sasToken}
            alt=""
            onError={(e) => handleDefaultCoinImg(e)}
          />
          <div>{wallet?.balance || 0 + " coins"}</div>
        </div>
      </div>

      <div className="flex justify-center items-center md:h-[93%] h-[85%] m-auto">
        <div className="content md:max-w-[80%] w-[100%] m-auto tracking-wider">
          <div className="flex justify-start pl-[0.3rem] md:pl-0 py-[2vh] bg-[#eeeeee]">
            <button
              className={`rounded-md px-3 py-2 font-semibold text-xs ${
                activeTab === "redeemProduct"
                  ? "bg-primary text-white"
                  : "bg-white text-[#AD8FCA] border border-[#EDF]"
              }`}
              onClick={() => setActiveTab("redeemProduct")}
            >
              Redeem Product
            </button>
            <button
              className={`mx-4 rounded-md px-3 py-2 font-semibold text-xs ${
                activeTab === "couponHistory"
                  ? "bg-primary text-white"
                  : "bg-white text-[#AD8FCA] border border-[#EDF]"
              }`}
              onClick={() => setActiveTab("couponHistory")}
            >
              Coupon History
            </button>
          </div>
          <div>
            {activeTab === "redeemProduct" ? (
              <RedeemableProductList />
            ) : (
              <CouponRedeemed />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RedeemTab;
