import {
  createContext,
  useState,
  useContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";
interface ProductContextType {
  product: any;
  setProduct: Dispatch<SetStateAction<any>>;
}

const ProductContext = createContext<ProductContextType>({
  product: null,
  setProduct: () => {},
});

export const ProductProvider = ({ children }: { children: ReactNode }) => {
  const [product, setProduct] = useState<any>(null);

  return (
    <ProductContext.Provider value={{ product, setProduct }}>
      {children}
    </ProductContext.Provider>
  );
};

export const useProduct = () => useContext(ProductContext);
