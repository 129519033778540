import axios from "axios";
import { URL } from "../config/URL.Config";

let clientCredentials: any;
let appSpecificId: string | undefined;

const getAccessToken = async () => {
  const response = await axios.post(
    `${URL.token.getAuthTokenClientCredentials}`,
    {
      clientId: clientCredentials.clientId,
      clientSecret: clientCredentials.clientSecret,
    }
  );

  return response.data.data.access_token;
};

export const getAccessTokenByAppSpecificId = async () => {
  const response = await axios.post(
    `${URL.token.getAuthTokenAppSpecificId(appSpecificId)}`
  );
  return response.data.data.token.access_token;
};

export const initializeClientCredentials = (
  clientId: string,
  clientSecret: string
) => {
  clientCredentials = {
    clientId: clientId,
    clientSecret: clientSecret,
  };
};

export const initializeAppSpecificId = (id: string) => {
  appSpecificId = id; // Update the top-level appSpecificId
};

export default getAccessToken;
